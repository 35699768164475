$progress-color: #ffb800;
$hover-color: rgba(130, 1, 113, 0.9);
$primary-color-dark: #941d62;
$primary-color-rgba: rgba(226, 25, 91, 1);
$primary-color-rgba-opacity-50: rgba(255, 73, 133, 0.5);

$primary-color: #8342c7;
//$button-color: #8342c7;
$secondary-color: #ffb800;

$disabled-color: #bdbdbd;

$secondary-dark-color: #2f2547;

$color-text: #f3f3f3;

// ------ MVP Colors -------

$dark-purple-color: #36285f;

//ui v3

$org-background-color: #1b1726;

$button-color: #8342c7;
$button-hover: #6618b6;
$button-press: #450e7f;
$button-disable: #8342c7;

$links-color: #9d69d3; //Hgh Lighted Text, Text Links, Secondary button (Outlined button)
$title-color: #cac4d0; // Button Text, Page Title
$text-color: #ffffff; // Input Filled, Form Title

$form-color: #2b243c;
$form-outline: #5c5277;
$border-select: #423863;
$input-placeholder: #85888e;

$error-color: #f97066;
$success-color: #16882f;
$pending-color: #ef6820;
