@charset "UTF-8";

@font-face {
  font-family: Avenir;
  font-weight: 100;
  font-style: normal;
  src: url('../../assets/fonts/AvenirNextCyr/AvenirNextCyr-UltraLight.ttf');
}

@font-face {
  font-family: Avenir;
  font-weight: 200;
  font-style: normal;
  src: url('../../assets/fonts/AvenirNextCyr/AvenirNextCyr-Thin.ttf');
}

@font-face {
  font-family: Avenir;
  font-weight: 300;
  font-style: normal;
  src: url('../../assets/fonts/AvenirNextCyr/AvenirNextCyr-Light.ttf');
}

@font-face {
  font-family: Avenir;
  font-weight: 300;
  font-style: normal;
  src: url('../../assets/fonts/AvenirNextCyr/AvenirNextCyr-Thin.ttf');
}

@font-face {
  font-family: Avenir;
  font-weight: 350;
  font-style: normal;
  src: url('../../assets/fonts/AvenirNextCyr/AvenirNextCyr-UltraLight.ttf');
}

@font-face {
  font-family: Avenir;
  font-weight: 400;
  font-style: normal;
  src: url('../../assets/fonts/AvenirNextCyr/AvenirNextCyr-Regular.ttf');
}

@font-face {
  font-family: Avenir;
  font-weight: 500;
  font-style: normal;
  src: url('../../assets/fonts/AvenirNextCyr/AvenirNextCyr-Medium.ttf');
}

@font-face {
  font-family: Avenir;
  font-weight: 600;
  font-style: normal;
  src: url('../../assets/fonts/AvenirNextCyr/AvenirNextCyr-Demi.ttf');
}

@font-face {
  font-family: Avenir;
  font-weight: 700;
  font-style: normal;
  src: url('../../assets/fonts/AvenirNextCyr/AvenirNextCyr-Bold.ttf');
}

// Raleway

@font-face {
  font-family: Raleway;
  font-weight: 100;
  font-style: normal;
  src: url('../../assets/fonts/Raleway/Raleway-Thin.ttf');
}

@font-face {
  font-family: Raleway;
  font-weight: 200;
  font-style: normal;
  src: url('../../assets/fonts/Raleway/Raleway-ExtraLight.ttf');
}

@font-face {
  font-family: Raleway;
  font-weight: 300;
  font-style: normal;
  src: url('../../assets/fonts/Raleway/Raleway-Light.ttf');
}

@font-face {
  font-family: Raleway;
  font-weight: 400;
  font-style: normal;
  src: url('../../assets/fonts/Raleway/Raleway-Regular.ttf');
}

@font-face {
  font-family: Raleway;
  font-weight: 500;
  font-style: normal;
  src: url('../../assets/fonts/Raleway/Raleway-Medium.ttf');
}

@font-face {
  font-family: Raleway;
  font-weight: 600;
  font-style: normal;
  src: url('../../assets/fonts/Raleway/Raleway-SemiBold.ttf');
}

@font-face {
  font-family: Raleway;
  font-weight: 700;
  font-style: normal;
  src: url('../../assets/fonts/Raleway/Raleway-Bold.ttf');
}

@font-face {
  font-family: Raleway;
  font-weight: 800;
  font-style: normal;
  src: url('../../assets/fonts/Raleway/Raleway-ExtraBold.ttf');
}

@font-face {
  font-family: Raleway;
  font-weight: 900;
  font-style: normal;
  src: url('../../assets/fonts/Raleway/Raleway-Black.ttf');
}

// Inter

@font-face {
  font-family: Inter;
  font-weight: 900;
  font-style: normal;
  src: url('../fonts/Inter/Inter_18pt-Black.ttf');
}

@font-face {
  font-family: Inter;
  font-weight: 600;
  font-style: normal;
  src: url('../fonts/Inter/Inter_18pt-SemiBold.ttf');
}

@font-face {
  font-family: Inter;
  font-weight: 500;
  font-style: normal;
  src: url('../fonts/Inter/Inter_18pt-Medium.ttf');
}

@font-face {
  font-family: Inter;
  font-weight: 400;
  font-style: normal;
  src: url('../fonts/Inter/Inter_18pt-Regular.ttf');
}
