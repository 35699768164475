/* You can add global styles to this file, and also import other style files */
@import '@angular/material/prebuilt-themes/indigo-pink.css';
@import 'cropperjs/dist/cropper.min.css';
@import 'assets/styles/colors';
@import 'assets/styles/fonts.scss';
@import 'assets/styles/material.scss';
@import 'quill/dist/quill.snow.css';

* {
  margin: 0;
  padding: 0;

  font-family: 'Avenir Next', sans-serif;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  text-rendering: optimizeLegibility;

  :focus {
    outline: unset;
  }
}

div.under-board div.scrollable mat-tab-body:first-child {
  overflow: visible !important;
}

.ql-editor h1,
h2,
h3 {
  font-weight: normal;
}

.baseClass {
  height: 37px;
  padding-top: 15px;
  padding-left: 16px;

  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-weight: 400;

  border: 1px solid transparent;
  border-radius: 5px;
}

.baseDarkClass {
  height: 37px;
  padding-top: 15px;
  padding-left: 16px;

  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: #aba6b8;

  background: #332b4d;
  border: 1.5px solid transparent;
  border-radius: 5px;
}

.invalidClass {
  border: 1.5px solid #e2195b;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  color: #ffffff !important;

  background: -webkit-linear-gradient(
    top,
    rgba(255, 255, 255, 0) 0%,
    rgba(0, 174, 255, 0) 50%,
    rgba(255, 255, 255, 0) 51%,
    rgba(0, 174, 255, 0) 100%
  );
  border: 0;
  -webkit-box-shadow: 0 0 0 1000px transparent inset;

  transition: background-color 5000s ease-in-out 0s;

  -webkit-text-fill-color: #ссс;
}

input:-webkit-autofill {
  -webkit-text-fill-color: #ffffff;
}

input:-webkit-autofill:focus {
  -webkit-text-fill-color: #ffffff;
}

.cdk-global-scrollblock {
  position: static !important;
  overflow-y: inherit !important;
  width: initial !important;
}

.mat-drawer-container {
  background-color: #ffffff;
}

body {
  position: relative;

  overflow-x: hidden !important;

  width: 100vw;
  margin: 0 !important;

  background-color: $org-background-color;
}

.mat-progress-bar-fill::after {
  background-color: $button-color;
}

.mat-progress-bar-buffer {
  background-color: #57364a;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
}

a {
  color: $button-color;
}

.scroll {
  padding-right: 7px;
}

::-webkit-scrollbar {
  display: block;
  width: 5px;
  height: 18px;
  margin-left: 24px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: rgba(160, 160, 160, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.mouse-icon {
  width: 150px;
}

.mobile-warning {
  display: none;
}

.only-for-mobile {
  display: none !important;
}

.ql-editor {
  padding: 0 !important;

  img {
    margin: 10px 0;
  }
}

@media (max-width: 1249px) {
  .hidden-for-mobile {
    display: none;
  }

  .only-for-mobile {
    display: block !important;
  }

  .mobile-warning {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;

    box-sizing: border-box;
    width: 100%;
    max-width: 100vw;
    height: 100%;
    max-height: 90vh;
    margin: 0 !important;
    padding: 3rem;

    text-align: center;
    text-transform: uppercase;
  }

  .disabled-for-mobile {
    pointer-events: none !important;
    opacity: 0.5 !important;
  }
  .hide {
    display: none !important;
  }
}

.timepicker {
  overflow: hidden;

  width: 350px !important;
  margin-top: 11px;

  border-radius: 16px !important;
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05) !important;

  &__body {
    padding: 36px 24px;
  }

  &__header {
    //padding: 10px 30px !important;
  }

  &-dial {
    &__hint {
      display: none !important;
    }

    &__container {
      gap: 12px;
      justify-content: center !important;
    }

    &__time {
      gap: 8px;
      align-items: center !important;

      span {
        padding-bottom: 6px;
        color: white;
      }
    }

    &__control {
      width: 65px !important;
      padding: 8px !important;

      font-size: 57px !important;
      color: white !important;

      background-color: #5c5277 !important;
      border-radius: 8px !important;

      transition: all 0.3s ease;
    }
  }
}

.timepicker-dial__item_active {
  color: #1b1726 !important;
  background-color: #ffffff !important;
}

.timepicker-period {
  overflow: hidden;
  border: 1px solid #ffffff;
  border-radius: 6px;

  &__btn {
    padding: 8px 10px !important;
    border-radius: 0 !important;
  }
}

.timepicker-right {
  transform: translateX(-222px);
}

.clock-face__number > span.disabled {
  opacity: 0.3;
}

.mdc-line-ripple {
  &::before,
  &::after {
    border-color: transparent !important;
  }
}

.mdc-text-field--filled {
  background-color: $form-color !important;
}

.mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled)
  .mdc-list-item__primary-text {
  color: #ffffff !important;
}

.mat-mdc-select-value-text {
  color: #ffffff !important;
}

.mat-mdc-form-field-infix {
  min-height: 61px !important;
  padding-top: 18px !important;
  padding-bottom: 0 !important;
}

.mat-mdc-autocomplete-panel,
div.mat-mdc-select-panel {
  margin-top: 4px;

  background-color: $form-color !important;
  border: 1px solid $border-select;
  border-radius: 8px !important;
  box-shadow:
    0 12px 16px -4px rgba(16, 24, 40, 0.08),
    0 4px 6px -2px rgba(16, 24, 40, 0.03) !important;
}

.mat-mdc-option {
  margin: 1px 6px;
  color: #ffffff !important;
  border-radius: 6px !important;
}

.mat-mdc-option.selected:not(.mdc-list-item--disabled):not(
    .mat-mdc-option-multiple
  ) {
  background-color: $form-outline !important;
}

.mat-mdc-option.selected::after {
  content: '';

  position: absolute;
  top: 40%;
  right: 16px;
  transform-origin: center;
  transform: rotate(-45deg);

  box-sizing: content-box;
  width: 14px;
  height: 6px;

  color: $links-color !important;

  opacity: 1;
  border-bottom: 2px solid currentColor;
  border-left: 2px solid currentColor;

  transition: opacity 90ms cubic-bezier(0, 0, 0.2, 0.1);
}

.mat-mdc-option.selected:not(.mdc-list-item--disabled)
  .mdc-list-item__primary-text {
  color: #ffffff !important;
}

.mat-pseudo-checkbox-minimal.mat-pseudo-checkbox-checked::after,
.mat-pseudo-checkbox-minimal.mat-pseudo-checkbox-indeterminate::after {
  color: $links-color !important;
}

@media (max-width: 450px) {
  .cdk-overlay-pane.mat-mdc-dialog-panel {
    max-width: 95vw !important;
  }
}

.mdc-form-field {
  flex-direction: row-reverse;

  font-family: 'Avenir Next', sans-serif !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  line-height: 20px !important;
  color: #ffffff !important;
}

.mat-mdc-option .mdc-list-item__primary-text {
  width: 100%;
}

.mat-internal-form-field {
  width: 100%;
}

.mdc-checkbox
  .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not(
    [data-indeterminate='true']
  )
  ~ .mdc-checkbox__background {
  border-color: white !important;
  border-radius: 6px;
}

.mdc-checkbox
  .mdc-checkbox__native-control:enabled:checked
  ~ .mdc-checkbox__background,
.mdc-checkbox
  .mdc-checkbox__native-control:enabled:indeterminate
  ~ .mdc-checkbox__background,
.mdc-checkbox
  .mdc-checkbox__native-control[data-indeterminate='true']:enabled
  ~ .mdc-checkbox__background {
  background: #9d69d3 !important;
  border: 3px solid #9d69d3 !important;
  border-radius: 6px;
}

.mdc-checkbox__background {
  border: 1px solid #ffffff !important;
}

.mdc-radio__outer-circle {
  border: 1px solid #d0d5dd !important;
}

.mdc-radio__inner-circle {
  background: #ffffff;
  border-color: #ffffff !important;
}

.mat-mdc-radio-button
  .mdc-radio
  .mdc-radio__native-control:enabled:checked
  + .mdc-radio__background
  .mdc-radio__outer-circle {
  background: #9d69d3;
  border-color: #9d69d3 !important;
}

.without::-webkit-datetime-edit-ampm-field {
  display: none !important;
}

input[type='time']::-webkit-clear-button {
  margin: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  margin: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.mat-mdc-text-field-wrapper {
  color: #ffffff;
  border-radius: 90px !important;
}

.mat-mdc-select-arrow,
.mat-mdc-select-placeholder,
.mat-mdc-form-field
  .mat-mdc-select.mat-mdc-select-invalid
  .mat-mdc-select-arrow,
.mat-form-field-invalid:not(.mat-form-field-disabled)
  .mat-mdc-form-field-infix::after {
  color: #94969c !important;
}

.mat-mdc-form-field-subscript-wrapper,
.mat-mdc-form-field-bottom-align::before {
  height: 0;
}

.ngx-timepicker__control--forth,
.ngx-timepicker-control__arrow {
  display: none !important;
}

.ngx-timepicker-control--active::after {
  display: none !important;
}

.ngx-timepicker-control--active {
  .ngx-timepicker-control__input {
    background-color: #564f6c !important;
  }
}

.ngx-timepicker__time-colon {
  margin-bottom: 2px;
  margin-left: 0 !important;
}

.ngx-timepicker {
  padding: 0 10px 0 20px;
  background-color: #423863;
  border-radius: 5px;
}

.ngx-timepicker-control {
  width: 28px !important;
  height: 28px !important;
  padding: 0 2px !important;
}

.ngx-timepicker-control__input {
  padding: 0 !important;
  font-size: 14px !important;
  background-color: #423863;
  border-radius: 5px;
}

.mat-mdc-dialog-container .mdc-dialog__surface {
  background-color: $org-background-color !important;
}

.table-container {
  overflow-x: auto;
  width: 100%;

  &::-webkit-scrollbar {
    height: 8px;
  }

  &::-webkit-scrollbar-track {
    background-color: $form-color;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $border-select;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: $form-outline;
  }
}
